<template>
  <v-col cols="12" md="6" lg="6" offset-lg="3" offset-md="3">
    <v-card class="elevation-1">
      <v-card-title>Payslip eBlast</v-card-title>
      <v-card-text>
        <v-form>
          <v-autocomplete
            v-model="type"
            outlined
            dense
            label="Type"
            :items="['PAYROLL','SPECIAL']"
            :class="formErrorFields.typeErrorMessage.length > 0 ? 'mb-3' : ''"
            :error-messages="formErrorFields.typeErrorMessage"
            @change="formError().remove('type', formErrorFields)"
          ></v-autocomplete>

          <v-autocomplete
            v-model="employee"
            outlined
            dense
            clearable
            multiple
            label="Employee"
            item-value="id"
            item-text="name"
            :loading="employeeLoading"
            :items="employees"
            :class="formErrorFields.employeeErrorMessage.length > 0 ? 'mb-3' : ''"
            :error-messages="formErrorFields.employeeErrorMessage"
            @change="formError().remove('employee', formErrorFields)"
          ></v-autocomplete>

          <v-text-field
            v-if="type==='PAYROLL'"
            :value="activePayPeriod.end"
            outlined
            dense
            placeholder="Payroll Period"
            label="Payroll Period"
            readonly
          ></v-text-field>

          <template v-else>
            <v-dialog
              ref="dialog"
              v-model="modalFromDate"
              :return-value.sync="fromDateValue"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fromDate"
                  label="From Date"
                  outlined
                  dense
                  placeholder="From Date"
                  v-bind="attrs"
                  v-on="on"
                  :class="formErrorFields.fromDateErrorMessage.length > 0 ? 'mb-3' : ''"
                  :error-messages="formErrorFields.fromDateErrorMessage"
                  @click="formError().remove('fromDate', formErrorFields)"
                ></v-text-field>
              </template>
              <v-date-picker v-model="fromDate" scrollable>
                <v-btn text color="primary" @click="modalFromDate=false">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalFromDate=false">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>

            <v-dialog
              ref="dialog"
              v-model="modalToDate"
              :return-value.sync="toDateValue"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="toDate"
                  label="To Date"
                  outlined
                  dense
                  placeholder="To Date"
                  v-bind="attrs"
                  v-on="on"
                  :class="formErrorFields.toDateErrorMessage.length > 0 ? 'mb-3' : ''"
                  :error-messages="formErrorFields.toDateErrorMessage"
                  @click="formError().remove('toDate', formErrorFields)"
                ></v-text-field>
              </template>
              <v-date-picker v-model="toDate" scrollable>
                <v-btn text color="primary" @click="modalToDate=false">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalToDate=false">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </template>

          <v-btn
            color="primary"
            :loading="loading"
            @click="showConfirmDialog=true">
            Process
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>

    <snackbar
      v-if="message.length > 0"
      :show="message.length > 0"
      :text="message"
      :color="messageStatus"
      @close="message=''"
    ></snackbar>

    <confirm-password
      :show="showConfirmDialog"
      @cancel="showConfirmDialog=false"
      @execute="submit">
    </confirm-password>
  </v-col>
</template>

<script>
import { ref } from '@vue/composition-api'
import get from '@/composables/get'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'
import ConfirmPassword from '@/components/ConfirmPassword.vue'
import formError from '@/composables/formErrorHandler/formError'

export default {
  metaInfo: {
    title: 'Payslip eBlast',
  },
  components: {
    snackbar,
    ConfirmPassword,
  },
  setup() {
    const loading = ref(false)
    const activePayPeriod = ref({})
    const formLoading = ref(false)
    const type = ref('PAYROLL')
    const employee = ref([])
    const employees = ref([])
    const employeeLoading = ref(false)
    const error = ref('')
    const message = ref('')
    const messageStatus = ref('')
    const showConfirmDialog = ref(false)
    const modalFromDate = ref(false)
    const fromDateValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const fromDate = ref('')
    const modalToDate = ref(false)
    const toDateValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const toDate = ref('')
    const formErrorFields = ref({
      typeErrorMessage: '',
      employeeErrorMessage: '',
      fromDateErrorMessage: '',
      toDateErrorMessage: '',
    })

    const {
      store,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const submit = async () => {
      showConfirmDialog.value = false
      const uri = type.value === 'PAYROLL'
        ? `${process.env.VUE_APP_URI}/api/payroll/reports/payslip/eblast`
        : `${process.env.VUE_APP_URI}/api/payroll/reports/payslip-special/eblast`

      const formData = type.value === 'PAYROLL' ? {
        type: type.value,
        employee: employee.value,
        payrollPeriod: activePayPeriod.value.end,
      } : {
        type: type.value,
        employee: employee.value,
        from_date: fromDate.value,
        to_date: toDate.value,
      }

      await store(uri, loading, formData, formErrorFields)
      message.value = responseMessage.value
      messageStatus.value = responseMessageStatus.value
    }

    const getPayPeriod = async () => {
      await get(`${process.env.VUE_APP_URI}/api/payroll/payroll-periods/get-active`, activePayPeriod, formLoading, error)
      if (error.value.data !== undefined) {
        message.value = error.value.data.message
        messageStatus.value = error.value.status
      }
    }

    get(`${process.env.VUE_APP_URI}/api/payroll/employees/true`, employees, employeeLoading)

    getPayPeriod()

    return {
      store,
      submit,
      modalFromDate,
      fromDateValue,
      fromDate,
      modalToDate,
      toDateValue,
      toDate,
      type,
      employee,
      employees,
      employeeLoading,
      loading,
      message,
      messageStatus,
      activePayPeriod,
      showConfirmDialog,
      formError,
      formErrorFields,
    }
  },
}
</script>
